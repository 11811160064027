import 'sanitize.css'
import 'sanitize.css/typography.css'
import 'sanitize.css/forms.css'
import '@mantine/core/styles.css'
const wrapPage = require('@raysync/common/config/wrap-page')
const { getCookie, setCookie } = require('@raysync/common/utils/cookie')
export const wrapPageElement = wrapPage

export const onClientEntry = () => {
  const mediaQueryList = window.matchMedia('(max-width: 768px)')
  localStorage.setItem('isMobile', mediaQueryList.matches)
  const hasReferrerValue = getCookie('hasReferrerValue')
  if (!hasReferrerValue || Date.now() > +hasReferrerValue + 24 * 60 * 60 * 1000) {
    let referrer = window.document.referrer
    if (referrer) {
      referrer = referrer + window.location.search.slice(referrer.indexOf('&') > 0 ? 1 : 0)
    } else {
      referrer = window.location.href
    }
    setCookie('referrer', referrer, 3)
    setCookie('hasReferrerValue', Date.now(), 1)
  }
}
