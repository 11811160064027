const intl = require('react-intl-universal')
const { LocaleService } = require('../services/locale-service')
const locales = {
  'en-US': require('../locales/en-US.json'),
  ja: require('../locales/ja.json'),
}

module.exports = ({ element, props }) => {
  const { pageContext } = props
  let currentLocale = process.env.GATSBY_LOCALE
  if (pageContext && pageContext.locale) currentLocale = pageContext.locale
  LocaleService.setCurrentLocale(currentLocale)
  intl.init({
    currentLocale,
    locales,
    warningHandler: () => {},
  })
  return element
}
